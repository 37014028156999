<template>
  <v-sheet
    color="secondary lighten-1"
    class="d-flex align-center justify-center px-4"
    height="100%"
  >
    <v-card
      class="mt-n6"
      elevation="6"
      color="primary darken-2 pa-6"
      max-width="500px"
    >
      <v-card-title class="white--text d-block text-center font-weight-medium">
        Esqueceu sua senha?
      </v-card-title>
      <v-card-text class="white--text text-center">
        Informe o seu e-mail de acesso que lhe enviaremos por e-mail as instruções para trocar sua senha.
      </v-card-text>
      <v-form
        ref="forgotPasswordForm"
        @submit.prevent="passwordrecovery()"
        class="mt-2"
      >
        <!-- <v-card-actions class="d-flex flex-column align-stretch mx-4 mb-4"> -->
        <v-text-field
          v-model="email"
          color="primary"
          label="E-Mail *"
          hide-details="auto"
          class="white"
          filled
          :disabled="loadingSave"
          :rules="[$rules.email]"
         
        />
        <div class="mt-4 d-flex justify-end">
          <v-btn
            class="mr-2"
            color="white" text
            @click="$router.go(-1)"
          >
            cancelar
          </v-btn>
          <v-btn
            color="success"
            type="submit"
            :loading="loadingSave"
          >
            Enviar
          </v-btn>
        </div>
      </v-form>
    </v-card>
  </v-sheet>
</template>
<script>
export default {
  name: "ForgotPassword",

  data: () => ({
    loading: false,
    loadingSave: false,
    email: "",
  }),
  methods: {
    passwordrecovery() {
      if (this.$refs.forgotPasswordForm.validate()) {
        this.loadingSave = true;
        this.$axios
          .put("/password-recovery/" + this.email)
          .then((response) => {
            this.$showMessage("success", "Código enviado para o seu Email");
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status == 400) {
                this.$showMessage(
                  "warning",
                  "Não encontramos nenhuma conta vinculada a esse os dados informados."
                );
                return;
              }
              this.$showMessage(
                "error",
                "Falha Inesperada na Recuperação de Senha"
              );
            }
          })
          .finally(() => {
            this.loadingSave = false;
          });
      }
    },
  },
};
</script>